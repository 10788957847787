import React from "react";
import { useNavigate } from "react-router-dom";
import Cars from "../assets/Cars.jpg";

const Book = () => {
  const navigate = useNavigate();
  const handleBookClick = () => {
    navigate("/book"); // Navigate to the '/book' route
  };
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gradient px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0 mt-12">
        {/* <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
          >
          <defs>
          <pattern
          id="e813992c-7d03-4cc4-a2bd-151760b470a0"
          width={200}
          height={200}
          x="50%"
          y={-1}
          patternUnits="userSpaceOnUse"
          >
          <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
          d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
          strokeWidth={0}
          />
          </svg>
          <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
          </svg>
        </div> */}
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-xl font-semibold leading-7 text-indigo-600">
                  How To
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Book a Ride
                </h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  Intelliride is the state contracted broker for Health First
                  Colorado. Intelliride manages NEMT services for the entire
                  state of Colorado. To book a ride, eligible members need to
                  contact intelliride.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src={Cars}
              alt=""
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <ul className="mt-4 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <span>
                      <strong className="font-semibold text-gray-900">
                        How to Schedule Transporation:
                      </strong>{" "}
                      You must schedule your ride at least two(2) business days
                      before your scheduled appointment. When scheduling your
                      trip, please have the following information: Your health
                      First Colorado ID number, Your date of birth, The street
                      address and contact phone number for your pickup and
                      drop-off location, Date and time your appointment begins
                      and ends, name of your doctor or facility and treatment
                      reason.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    {/* <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Below are several options to book rides:
                      </strong>{" "}
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    {/* <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Phone:
                      </strong>{" "}
                      Call Intelliride's toll-free phone (1-855-489-49999) or
                      303-398-2155 during normal business hours (Monday-Friday,
                      8 am to 5 pm) to speak with a live agent who will guide
                      you through the reservation process.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    {/* <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Chat:
                      </strong>{" "}
                      Chat with a live agent during normal business hours at:
                      gointelliride.com/Colorado
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 rounded-lg bg-gradient-to-b from-[#EBEAFC] to-[#F8FAFC] p-6 shadow-lg sm:flex-row sm:justify-between">
        <strong className="text-xl text-indigo-600 sm:text-xl">
          {" "}
          Book Your Ride With Us!{" "}
        </strong>

        <button
          className="inline-flex items-center gap-2 rounded-full border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:bg-indigo-600/90"
          onClick={handleBookClick}
        >
          <span className="text-sm font-medium"> Let's Get Started </span>

          <svg
            className="h-5 w-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default Book;
